import DialogSurvey from "./ModalSurvey";
import { Links } from "./data";
import {
  Content,
  DashboardWrapper,
  Drawers,
  Line,
  LinkMain,
  OverLay,
} from "./styles";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import LogoutIcon from "@mui/icons-material/Logout";
import Header from "components/GlobalHeaderDashboard";
import Popup from "components/Popup";
import SVG from "components/SVG";
import OverlayContent from "layouts/components/OverlayContent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { HandleOpenDrawer, handleLogout } from "redux/slices/auth";
import { MenuDrawer } from "redux/slices/menu";
import { handleGetSurvey } from "redux/slices/wallet";
import { dashboard } from "routes/Dashboard";
import { GetUser } from "utits/auth";

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = GetUser();
  const dispatch = useDispatch();
  const [actives, setActives] = useState(false);
  const [openSurveyModal, setOpenSurveyModal] = useState(false);
  const [isChildrenOpen, setIsChildrenOpen] = useState(false);
  const { menuDrawer } = useSelector((state) => state.menu);

  const { openDrawer } = useSelector((state) => state.auth);

  useEffect(() => {
    const title = dashboard.filter(
      (item) => item.path === location.pathname
    )?.[0];
    title
      ? (document.title = `${title.name} | Toearnnow`)
      : (document.title = "Toearnnow");
    // dispatch(handleGetSurvey()).then((res) => {
    //   if (!res?.data?.data) {
    //     setOpenSurveyModal(true);
    //   }
    // });
  }, [location, dispatch]);

  const logout = () => {
    dispatch(handleLogout(navigate));
  };

  const handleActiveMenu = (name, isActive) => {
    if (actives == name && !isActive) {
      setActives(false);
    } else {
      setActives(name);
    }
  };

  return (
    <DashboardWrapper>
      <Drawers.Container
        openDrawer={openDrawer}
        style={{ zIndex: 22, overflowY: "scroll" }}
      >
        <Drawers.LogoWrapper>
          <Link to="/">
            <SVG name="vfc" />
          </Link>
          <Line />
        </Drawers.LogoWrapper>

        {/* <Link to={PATH.DASHBOARD.ACCOUNT}>
          <Drawer.Nav >
            <Drawer.IconWrapper>
              {user?.reward < 6 && (<SVG name={`reward${user?.reward}`} />)}
            </Drawer.IconWrapper>
          </Drawer.Nav>
        </Link> */}

        {dashboard.map((route, index) => {
          const isActive = route.path === location.pathname;
          let border;

          if (user) {
            if (route.name === "Home" || route.name === "Profile") {
              border = (
                <div
                  key={index}
                  style={{
                    border: "1px solid rgba(255, 222, 227, 0.2)",
                    margin: "10px 0",
                  }}
                ></div>
              );
            }
            return (
              <>
                {route?.children ? (
                  <>
                    <Drawers.Children
                      active={actives == route.name || isActive}
                      style={{ marginBottom: "20px" }}
                      key={route.name}
                    >
                      <div>
                        <Drawers.Nav
                          active={actives == route.name}
                          onClick={() => {
                            handleActiveMenu(route.name, isActive);
                          }}
                        >
                          <Drawers.IconWrapper>
                            {route.icon}
                          </Drawers.IconWrapper>
                          <Drawers.Name active={isActive}>
                            {route.name}
                          </Drawers.Name>
                        </Drawers.Nav>
                      </div>
                      {actives == route.name && (
                        <>
                          {route?.children?.map((e, i) => {
                            return (
                              <>
                                <Drawers.ChildrenLink
                                  active={menuDrawer === e.title ? true : false}
                                  key={i}
                                  onClick={() => {
                                    dispatch(MenuDrawer(e.title));
                                    dispatch(HandleOpenDrawer(false));
                                    setIsChildrenOpen(
                                      e?.children && !isChildrenOpen
                                    );
                                  }}
                                >
                                  <Link to={e?.link} style={{ color: "white" }}>
                                    <ArrowRightIcon />
                                    {e?.name}
                                  </Link>
                                </Drawers.ChildrenLink>
                                {isChildrenOpen &&
                                  e?.children?.map((child2, j) => {
                                    return (
                                      <Drawers.ChildrenF2Link
                                        active={
                                          menuDrawer === child2.title
                                            ? true
                                            : false
                                        }
                                        key={j}
                                        onClick={() => {
                                          dispatch(MenuDrawer(child2.title));
                                          dispatch(HandleOpenDrawer(false));
                                        }}
                                      >
                                        <Link
                                          to={child2?.link}
                                          style={{ color: "white" }}
                                        >
                                          <ArrowRightIcon />
                                          {child2?.name}
                                        </Link>
                                      </Drawers.ChildrenF2Link>
                                    );
                                  })}
                              </>
                            );
                          })}
                        </>
                      )}
                    </Drawers.Children>
                    {/* {border} */}
                  </>
                ) : (
                  <>
                    <Drawers.Children
                      key={route.name}
                      active={isActive}
                      style={{ marginBottom: "20px" }}
                    >
                      <Link
                        to={route.path}
                        onClick={() => {
                          setActives(false);
                          dispatch(HandleOpenDrawer(false));
                        }}
                      >
                        <Drawers.Nav active={isActive}>
                          <Drawers.IconWrapper>
                            {route.icon}
                          </Drawers.IconWrapper>
                          <Drawers.Name active={isActive}>
                            {route.name}
                          </Drawers.Name>
                        </Drawers.Nav>
                      </Link>
                    </Drawers.Children>
                    {border}
                  </>
                )}
              </>
            );
          } else {
            if (
              route.name !== "Account" &&
              route.name !== "Wallet" &&
              route.name !== "Kyc" &&
              route.name !== "Profile"
            )
              return (
                <div key={route.name} style={{ marginBottom: "20px" }}>
                  <Link to={route.path} key={route.name}>
                    <Drawers.Nav active={isActive}>
                      <Drawers.IconWrapper>{route.icon}</Drawers.IconWrapper>
                      <Drawers.Name>{route.name}</Drawers.Name>
                    </Drawers.Nav>
                  </Link>
                  {/* {border} */}
                </div>
              );
          }
        })}
        {/* <Drawers.Children style={{ marginBottom: "20px" }}>
          <Link to="/auth/contact" key="/auth/contact">
            <Drawers.Nav>
              <Drawers.IconWrapper>
                <ContactSupportIcon style={{ color: "#a100a5" }} />
              </Drawers.IconWrapper>
              <Drawers.Name>Contact</Drawers.Name>
            </Drawers.Nav>
          </Link>
        </Drawers.Children> */}
        <Drawers.Nav onClick={logout} style={{ cursor: "pointer" }}>
          <Drawers.IconWrapper>
            <LogoutIcon style={{ color: "#a100a5" }} />
          </Drawers.IconWrapper>
          <Drawers.Name>Logout</Drawers.Name>
        </Drawers.Nav>

        <div>
          <LinkMain>
            {Links?.map((e, i) => (
              <a key={i} href={e.link} target="_blank">
                <img
                  key={i}
                  src={e?.image}
                  alt=""
                  style={{ width: "35px", height: "35px" }}
                />
              </a>
            ))}
          </LinkMain>
        </div>
      </Drawers.Container>

      <Content openDrawer={openDrawer}>
        <Header></Header>
        <Outlet />
        {/* {location.pathname !== "/earning" && <Footer />} */}

        <Popup />
      </Content>

      <OverlayContent />
      <OverLay
        onClick={() => dispatch(HandleOpenDrawer(false))}
        openDrawer={openDrawer}
      />

      {/* Modal for survey */}
      {/* <DialogSurvey
        open={openSurveyModal}
        onClose={() => setOpenSurveyModal(false)}
        onSubmit={(formData) => {
          console.log("Submitted Data:", formData);
          setOpenSurveyModal(false);
        }}
      /> */}
    </DashboardWrapper>
  );
};
export default Dashboard;
