import BannerCountDown from "./BannerCountDown";
import Faq from "./Faq";
import Newspapers from "./Newspapers";
import Trusted from "./Trusted";
import Allocation from "./allocation";
import Banner from "./banner";
import { StyledButton } from "./banner/Banner.Styled";
import Footer from "./footer";
import Header from "./header";
import Roadmap from "./roadmap";
import { Box, Modal, Typography } from "@mui/material";
import Adroin from "assets/svg/Adroin.svg";
import IOS from "assets/svg/IOS.svg";
import bg from "assets/svg/bg-Trusted.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function HomeMain() {
  const [openModal, setOpenModal] = useState(true);
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClickHere = () => {
    navigate("/auth/login");
  };

  return (
    <div
      style={{
        background: "#08021c",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Modal thông báo */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "linear-gradient(90deg, #780d6957 0%, #ec01744d 100%)",
            padding: 4,
            borderRadius: 3,
            boxShadow: 24,
            width: "100%",
            maxWidth: 600,
            height: "auto",
            animation: "slide-up 1.2s ease-out",
            backdropFilter: "blur(8px)",
          }}
        >
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ color: "#fff" }}
          >
            Attention toearnnow Users!
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2, color: "#fff" }}>
            <ul>
              <li>
                It's your moment to step up! Download NOWchain's mobiNODE app
                now to become a validator.
              </li>
              <li>
                Secure the Network: Your participation keeps our blockchain
                safe. Earn Rewards: Get 100 NOW coins just for joining as a
                validator. Refer and Earn More: Refer friends to join as
                validators through your unique link and earn additional NOW
                coins for each successful referral.
              </li>
              <li>
                Don’t miss this chance to be a key player in shaping the future
                of NOWchain.{" "}
              </li>
              <li>
                Download, Validate, Refer, and Earn NOW!
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "20px",
                    gap: "20px",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  <a
                    href="https://apps.apple.com/vn/app/mobinode/id6738374000"
                    target="_blank"
                  >
                    <img src={IOS} alt="" />
                  </a>

                  <a
                    href="https://play.google.com/store/apps/details?id=com.nowchain.mobi_node"
                    target="_blank"
                  >
                    <img src={Adroin} alt="" />
                  </a>
                </div>
              </li>
            </ul>
          </Typography>
          <StyledButton>
            <button
              style={{ maxWidth: "none", width: "100%" }}
              onClick={handleCloseModal}
            >
              Close
            </button>
          </StyledButton>
        </Box>
      </Modal>

      {/* Các phần khác của trang */}
      <Header />
      <Banner />
      <Newspapers />
      <Trusted />
      {/* <NftItem /> */}
      <Allocation />
      <Roadmap />
      <BannerCountDown />
      <Faq />
      <Footer />

      <img
        src={bg}
        alt=""
        style={{
          position: "absolute",
          top: "50%",
          left: "-25%",
          width: "60%",
          overflow: "hidden",
          zIndex: "1",
        }}
      />
    </div>
  );
}
