import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { MessageToastError, MessageToastSuccess } from "components/Message";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { handleCreateSurvey } from "redux/slices/wallet";
import { themeLight } from "theme/globalStyles";
import * as yup from "yup";

const DialogSurvey = ({ open, onClose }) => {
  const [showMore, setShowMore] = useState(false);

  const dispatch = useDispatch();

  const validationSchema = yup.object({
    token_to_nowc: yup
      .number()
      .positive("Value must be positive")
      .typeError("Token to NOWC is required")
      .required("Token to NOWC is required"),
    nowc_to_share: yup
      .number()
      .positive("Value must be positive")
      .typeError("NOWC to Share is required")
      .required("NOWC to Share is required"),
    nowc_to_coin: yup
      .number()
      .positive("Value must be positive")
      .typeError("NOWC to Coin is required")
      .required("NOWC to Coin is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    dispatch(handleCreateSurvey(data)).then((res) => {
      if (res.data?.error) {
        MessageToastError(res?.data?.message);
      } else {
        MessageToastSuccess(res?.data?.message);
        onClose();
        reset();
      }
    });
  };

  const handleToggleContent = () => {
    setShowMore(!showMore);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      fullWidth
      maxWidth="sm"
      sx={{
        "& .MuiDialog-paper": {
          background: "linear-gradient(90deg, #780d6957 0%, #ec01744d 100%)",
          color: "#fff",
          margin: "5px !important",
          width: "calc(100% - 24px)",
          borderRadius: 3,
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",
          padding: 2,
          backdropFilter: "blur(8px)",
        },
      }}
    >
      <DialogTitle style={{ color: "#fff", paddingTop: "5px" }}>
        Attention toearnnow Users!
      </DialogTitle>
      <DialogContent sx={{ padding: "10px 14px" }}>
        <ul style={{ listStyleType: "disc !important" }}>
          <li>
            It's your moment to step up! Download NOWchain's mobiNODE app now to
            become a validator.
          </li>
          <li>
            Secure the Network: Your participation keeps our blockchain safe.
            Earn Rewards: Get 100 NOW coins just for joining as a validator.
            Refer and Earn More: Refer friends to join as validators through
            your unique link and earn additional NOW coins for each successful
            referral.
          </li>
          <li>
            Don’t miss this chance to be a key player in shaping the future of
            NOWchain.{" "}
          </li>
          <li>Download, Validate, Refer, and Earn NOW!</li>
          Download
          <span
            onClick={handleToggleContent}
            style={{
              color: "#00bcd4",
              cursor: "pointer",
              textDecoration: "underline",
              marginLeft: 4,
            }}
          >
            {showMore ? "Show Less" : "Show More"}
          </span>
        </ul>

        <Box
          sx={{
            height: showMore ? "auto" : "0px",
            display: showMore ? "block" : "none",
            marginTop: 2,
            color: "#fff",
            transition: "height 0.3s ease-in-out",
            opacity: showMore ? 1 : 0,
          }}
        >
          <Typography variant="body2" paragraph>
            Great. We're glad you're here. Let's take a moment to explore the
            unparalleled benefits and potential of owning stocks. Before you
            decide which investment to convert to, this exclusive opportunity
            allows you to deep dive into the advantages of stock ownership
            before they're available to the broader market. Here's how you can
            transform your crypto into something even more valuable.
          </Typography>
          <ul>
            <li>
              <strong>Stability:</strong> Move away from the wild swings of
              cryptocurrency to the more predictable growth patterns of
              established U.S. companies.
            </li>
            <li>
              <strong>Diversify for Safety:</strong> Diversify your portfolio
              before the masses can, positioning yourself for potential gains
              that others can only dream of.
            </li>
            <li>
              <strong>Real-World Value:</strong> Stocks represent ownership in
              companies that produce tangible products or services, offering a
              more concrete investment foundation.
            </li>
            <li>
              <strong>Dividends:</strong> Get regular payouts from dividends,
              adding a steady income stream to your investment portfolio,
              something most cryptocurrencies can't provide.
            </li>
            <li>
              <strong>Influence and Insight:</strong> As a shareholder, you're
              not just a number; you have a voice in company decisions and
              access to insider financials, giving you an edge over crypto.
            </li>
            <li>
              <strong>Tax Advantages:</strong> Benefit from potentially
              favorable tax treatments on capital gains and dividends, which
              could mean more money stays in your wallet.
            </li>
            <li>
              <strong>Success Stories:</strong> Look at the example of Coinbase
              and MARA - early investors in these companies have seen their
              initial investments multiply many times over.
            </li>
          </ul>

          <Typography
            variant="h6"
            component="h2"
            sx={{ color: "#fff", marginBottom: 2 }}
          >
            Your Exclusive Conversion Rate:
          </Typography>
          <ul>
            <li>1 NOW TOKEN = 0.088 NOWC</li>
            <li>1 NOWC = X NOW COIN (NOWCHAIN)</li>
            <li>1 NOWC = 0.015 shares (share price $10)</li>
          </ul>

          <Typography variant="body1" paragraph>
            The time to diversify and secure your financial future is now!
          </Typography>

          <Box
            sx={{
              backgroundImage:
                "linear-gradient(115.36deg, rgba(244, 0, 116, 0.4) 33.92%, rgba(37, 19, 81, 0.4) 99.86%)",
              padding: "8px",
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              marginTop: 2,
              fontSize: "14px",
              paddingLeft: "25px",
            }}
          >
            <strong>WARNING ⚠️</strong> <br />
            <ul>
              <li>
                {" "}
                Please ensure you accurately fill out the number of tokens you
                wish to convert. Incorrect or incomplete entries may result in
                delays in processing your transaction.{" "}
              </li>
              <li>
                {" "}
                To avoid any inconvenience, please double-check the number of
                tokens you are holding before submission.
              </li>
            </ul>
          </Box>
        </Box>

        {/* Form fields */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <ThemeProvider theme={themeLight}>
            <Controller
              name="token_to_nowc"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Token to NOWC"
                  margin="normal"
                  type="number"
                  {...field}
                  error={!!errors.token_to_nowc}
                  helperText={errors.token_to_nowc?.message}
                />
              )}
            />
            <Controller
              name="nowc_to_share"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="NOWC to Share"
                  margin="normal"
                  type="number"
                  {...field}
                  error={!!errors.nowc_to_share}
                  helperText={errors.nowc_to_share?.message}
                />
              )}
            />
            <Controller
              name="nowc_to_coin"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="NOWC to Coin"
                  margin="normal"
                  type="number"
                  {...field}
                  error={!!errors.nowc_to_coin}
                  helperText={errors.nowc_to_coin?.message}
                />
              )}
            />

            <DialogActions>
              <Button onClick={onClose} variant="outlined">
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </DialogActions>
          </ThemeProvider>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default DialogSurvey;
